<template>
  <div class="w-100 p-l-30 p-r-30">
    <div class="contents-header">
      <p>
        <router-link :to="{ name: 'Tournaments', query: this.createSearchQuery() }" class="ashen-link">大会管理
        </router-link>
        <i
            class="fas fa-angle-right form-control-color"></i> 大会一括新規登録
      </p>
    </div>
    <!-- 12-6 -->
    <div v-if="batchRegistrationTournamentSuccessMsgScreen">
      <div class="card border-0">
        <div class="text-first">
          <div class="card-body mx-auto">
            <div class="d-flex text-center flex-column">
              <div v-for="mes in tournamentUploadMessage">
                <p>{{ mes }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 12-5 -->
    <div v-else-if="batchRegistrationTournamentConfirmationScreen">
      <div>
        <div class=" border-0 text-first">
          <div class="card-body mx-auto">
            <div class="d-flex justify-content-center">
              <p class="font-16">一括登録を実行します。よろしいですか？<br>処理の終了には数分かかる場合があります。</p>
            </div>
            <div class="row mt-4 d-flex justify-content-between">
              <div class="col">
                <button :disabled="loading" class="btn cancel w-100 font-weight-bold mx-auto mt-4"
                        @click="addBulkTournament">キャンセル
                </button>
              </div>
              <div class="col">
                <button :disabled="loading"
                        class="btn disable ok w-100 font-weight-bold mx-auto mt-4" @click="submitFile">登録
                </button>
              </div>
            </div>
            <div v-if="loading" class="mt-5 d-flex justify-content-center">
              <p>処理中です。処理の終了には数分かかる場合があります。</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 12-3 -->
    <div v-else-if="addNewBulkTournamentScreen">
      <div class="container">
        <div class="text-center mt-5">
          <div>
            <button class="btn btn-primary btn-download height-44">
              <img class="template-icon" src="../assets/images/document-text.png">
              <a class="text-decoration-none text-light" download href="/download/batch_template_tournaments.xlsx">テンプレートファイルのダウンロード</a>
            </button>
          </div>
          <div class="input-group input-group-file w-100 mt-5 mx-auto">
            <input class="form-control selected-file" placeholder="ファイル選択" type="text"/>
            <span class="input-group-btn"><span class="btn btn-file height-48"><img
                src="../assets/images/arrow-bar-up.svg">
              <input ref="file" accept=".xls, .xlsx" type="file" @change="fileSelected"/></span></span>
          </div>
          <div class="mt-5 row button-container btn-container mx-auto">
            <div class="col p-l-0">
              <button class="btn btn-light bg-white ashen-link text-decoration-none light border-0 w-100"
                      @click="backToTournament">キャンセル
              </button>
            </div>
            <div class="col p-r-0">
              <button :disabled="Object.keys(this.file).length === 0 && this.file.constructor === Object"
                      class="btn col btn-primary btn-outline-light font-weight-bold w-100"
                      @click="batchRegistrationTournamentConfirmation">登録
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {app, gender, datatable, apiConfig} from "@/constants"
import Datatable from "../components/datatable/Datatable"
import DatePicker from "../components/datepicker/DatePicker"
import {mapGetters} from "vuex"
import {AdminUploadResult} from "../assets/jsadminclient"
import Modal from "../components/modal/Modal";
import Common from "jsadminclient/common";

export default {
  name: "TournamentBatchRegistration",
  components: {Datatable, DatePicker, Modal},
  props: {
    defaultResponse: {type: Object, default: null}
  },
  data() {
    return {
      app: app,
      apiConfig: apiConfig,
      response: {
        sort: '',
        order: 'desc',
        current_page: 1,
        per_page: datatable.default.perPage
      },
      tournamentStatus: 0,
      tournamentStatuses: {
        0: 'すべて',
        1: '近日開始',
        2: '期間中',
        3: '終了',
      },
      file: {},
      reloadKey: 1,
      loading: false,
      addNewBulkTournamentScreen: false,
      batchRegistrationTournamentConfirmationScreen: false,
      batchRegistrationTournamentSuccessMsgScreen: false,
      tournamentUploadMessage: [],
      columns: datatable.columns.tournaments,
      tournamentId: '',
      jtaTournament: {},
      tournament: {},
      prefectures: {},
      errors: []
    };
  },
  mounted() {
    this.apiConfig.headers["X-API-Token"] = this.config.token;
    this.addBulkTournament()
  },
  computed: {
    ...mapGetters(["config"]),
  },
  methods: {
    addBulkTournament() {
      this.file = {}
      this.batchRegistrationTournamentConfirmationScreen = false
      this.addNewBulkTournamentScreen = true;
    },
    backToTournament() {
      this.addNewBulkTournamentScreen = false;
      this.batchRegistrationTournamentConfirmationScreen = false;
      this.batchRegistrationTournamentSuccessMsgScreen = false;
      this.$router.push({
        name: 'Tournaments',
        params: {defaultResponse: this.defaultResponse},
        query: this.createSearchQuery()
      })
    },
    batchRegistrationTournamentConfirmation() {
      this.batchRegistrationTournamentConfirmationScreen = true;
      this.batchRegistrationTournamentSuccessMsgScreen = false;
      this.addNewBulkTournamentScreen = false;
    },
    batchRegistrationTournamentSuccessMsg() {
      this.batchRegistrationTournamentSuccessMsgScreen = true;
      this.addNewBulkTournamentScreen = false;
      this.batchRegistrationTournamentConfirmationScreen = false;
    },
    getApiConfig() {
      return new AdminApi.ApiConfig(
          this.apiConfig.basePath,
          this.apiConfig.headers
      );
    },
    fileSelected() {
      this.file = this.$refs.file.files[0];
      $(".selected-file").val(this.$refs.file.files[0].name)
    },
    async submitFile() {
      try {
        this.loading = true;
        const api = new AdminApi.JTATournamentApi(this.getApiConfig());
        var result = await api.jtaTournamentsUploadPostAsync(
            this.file
        )
        if (result) {

          let res = AdminUploadResult.createFromJson(result)
          let mes = res.successCount + "件　登録しました。"
          if (res.updated) {
            this.tournamentUploadMessage.push(mes)
          }

          if (res.failureCount > 0) {
            mes = res.failureCount + "件　エラーになりました。"
            this.tournamentUploadMessage.push(mes)
          }

          res.failureReasons.forEach((item) => {
            this.tournamentUploadMessage.push(item)
          })

          this.batchRegistrationTournamentSuccessMsg()
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async getPrefecture() {
      try {
        const api = new AdminApi.SystemApi(this.getApiConfig());
        var result = await api.systemPrefecturesGetAsync();
        this.prefectures = result.items;
      } catch (e) {
        this.prefectures = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    createSearchQuery() {
      return Common.appendSearchQuery(this.$route.query)
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-file {
  position: relative;
  overflow: hidden;
  border: 1px solid #E0B1B7;
  height: 32px;
  line-height: 30px;
  border-radius: 0px;
}

.template-icon {
  margin: 0px 8px;
}

.fa-upload {
  color: #f2ecf0;
}

.date-range {
  width: 21%;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 999px;
  text-align: right;
  opacity: 0;
  outline: none;
  background: #fff;
  cursor: pointer;
  display: block;
}

.form-control:not(select) {
  //background: #F2ECF0;
  border: none;
}

::placeholder {
  color: #333333;
}

.custom-button {
  background: #c95d6c;
  color: #ffffff;
  border-radius: 4px;
  width: 160px;
}

.custom-button-outline.btn {
  background: #ffffff;
  color: #c95d6c;
  border: 1px solid #c95d6c;
  border-radius: 4px;
  width: 160px;
}

.card-container {
  padding: 7rem !important;
  padding-top: 2rem !important;
}

.filter-select-long {
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  height: 44px !important;
}

.ok {
  //width: 414px;
  height: 44px;
  color: white;
  background-color: #c95d6c;
}

.ok:active {
  color: white;
  background-color: #c95d6c;
}

.cancel {
  //width: 414px;
  height: 44px;
  color: #333333;
  border: none;
  background-color: #ffffff;
}

.cancel:active {
  color: #c95d6c;
  background-color: #ffffff;
}

.delete {
  //width: 200px;
  height: 44px;
  color: white;
  background-color: #333333;
}

.delete:active {
  color: white;
  background-color: #333333;
}

.ok-min {
  width: 200px;
  height: 44px;
  color: white;
  background-color: #c95d6c;
}

.ok-min:active {
  color: white;
  background-color: #c95d6c;
}

.cancel-min {
  width: 200px;
  height: 44px;
  color: #333333;
  border: none;
  background-color: #ffffff;
}

.cancel-min:active {
  color: #333333;
  background-color: #ffffff;
}

a {
  color: #333333;
}

.filter-select {
  //background-image: url('./src/assets/images/down.png');
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  height: 32px !important;
}

.filter-search-form {
  border: 1px solid #dee2e6 !important;
  height: 32px !important;
}

.custom-select-year {
  width: 28%;
  margin-right: 23px;

  &:first-child {
    width: 40%;
  }

  &:last-child {
    width: 28%;
    margin-right: 0px !important;
  }
}

@media only screen and (min-width: 800px) {
  .card {
    &-body {
      padding-left: 120px;
      padding-right: 120px;
    }
  }
  .btn, .input-group {
    &-download {
      width: 320px;
    }

    &-file, &-container {
      max-width: 745px !important;
    }
  }
}

@media only screen and (max-width: 800px) {
  .card {
    &-body {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}

.tournamentbatchregistration {
  .btn {
    &-primary {
      &:disabled {
        pointer-events: none;
        opacity: 0.65;
        background-color: #C95D6C;
      }
    }
  }
}
</style>
